<template>
  <div class="flex w-fit">
    <div v-for="(button, idx) in data" :key="idx">
      <Button
        :label="button.title && button.title"
        :to="
          button.pageLink !== null
            ? localePathByType(
                button.pageLink?.__typename as string,
                button.pageLink?.slug as string
              )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="'solid'"
        :class="[props.childClass ? props.childClass : { 'ml-4': idx === 1 }]"
        :color="button.variant === 'orange' ? 'default' : button.variant"
        :icon="button.icon"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CallToActionFragment } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

const props = defineProps({
  data: {
    type: Array as PropType<CallToActionFragment[]>
  },
  childClass: {
    type: String,
    default: ''
  }
})
</script>
